<template>
  <main v-if="tipo == 'noticias'">
    <div class="div-pai" v-for="noticia in noticias" :key="noticia.idNoticias">
      <div id="principal">
        <div class="header-card">
          <div class="sombra-imagem"></div>
          <div class="div-imagem"> 
            <img id="imagem-head" v-bind:src="'https://direto.smgeo.com.br/files/'+ noticia.fileImagem" />
          </div>
        </div>
        <div id="header">
          <h1>{{ noticia.tituloNoticias }}</h1>
          <img src="../assets/LOGO_NICE_PLANET.png" id="logo" alt="logo" />
        </div>
      </div>

      <div id="secundaria"></div>
      <div id="cardNoticias">
        <div style="width: 93%; border: 0" class="card">
          <div class="card-header resumo">
            {{ noticia.resumoNoticias }} <br />
            <span id="data">
              {{ noticia.data_cadastroNoticias }}
            </span>
          </div>
          <div class="card-body">
            <p v-html="noticia.conteudoNoticias" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </main>

  <main v-else>
    <div v-for="noticia in noticias" :key="noticia.idTutorial">
      <div id="principalTutorial">
        <div class="header-card">
          <div class="sombra-imagem"></div>
          <div class="div-imagem"> 
            <img id="imagem-head" v-bind:src="'https://direto.smgeo.com.br/files/'+ noticia.fileImagem" />
          </div>
        </div>
        <div id="headerTutorial">
          <h1>{{ noticia.tituloTutorial }}</h1>
          <img src="../assets/LOGO_NICE_PLANET.png" id="logo" alt="logo" />
        </div>
      </div>

      <div id="secundaria"></div>
      <div id="cardTutorial">
        <div style="width: 93%; border: 0" class="card">
          <div class="card-header resumo">
            {{ noticia.resumoTutorial }} <br />
            <span id="data">
              {{ noticia.data_cadastroTutorial }}
            </span>
          </div>
          <div class="card-body">
            <p v-html="noticia.conteudoTutorial" class="card-text"></p>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import api from "../services/api";

export default {
  data() {
    return {
      noticias: [],
      tipo: String,
    };
  },
  mounted() {
    this.getNoticias();
  },
  methods: {
    async getNoticias() {
      let tipo;
      const id = this.$route.params.id;

      if (this.$route.params.tipo == "noticias") {
        tipo = this.$route.params.tipo;
      } else if (this.$route.params.tipo == "tutorial") {
        tipo = this.$route.params.tipo;
      }

      const response = await api.get(`/api/${tipo}/${id}`);

      if (response.status == 200) {
        this.noticias = response.data;
        this.tipo = tipo;
      } else {
        console.error("Ocorreu um erro na API");
      }
    }
  }
};
</script>

<style>
@media only screen and (max-width: 600px) {
  img, svg {
    width: 250px;
    height: 215px;
    display: flex;
  }

  .header-card {
    overflow: hidden !important;
  }

  figcaption {
    width: 250px
  }

  blockquote {
    display: inline;
  }

  figure {
    background-size: 500px 500px !important;
    width: 0 !important;
  }

  h2 div img {
    width: 300px !important;
  }


  #header h1 {
    font-size: 22px !important;
  }

  #header {
    padding: 5px 25px !important
  }

  #headerTutorial {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  #imagem-head {
    object-fit: cover;
    height: -webkit-fill-available;
    width: 100%;
    z-index: 999;
  }

  .header-card {
    position: relative;
    overflow-y: hidden;
    transform-style: preserve-3d;
    background-attachment: fixed;
  }

  #cardNoticias
    > div
    > div.card-body
    > p
    > div:nth-child(1)
    > figure
    > a
    > img {
    width: 250px;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  h2 div img {
    width: 750px
  }

  figure img{
    display: flex;
    width: 1050px;
  }

  #cardNoticias > div > div.card-body > p > div:nth-child(1) > figure {
    width: 30%;
  }
}

#imagem-head {
  object-fit: cover;
  height: -webkit-fill-available;
  width: 100%;
  height: 100vh;
  z-index: 999;
}

.sombra-imagem {
  position: absolute;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  width: 100%;
  height: 100vh;
}

.div-imagem {
  position: relative;
  height: 400px;
  transform-style: inherit;
}

.header-card {
  position: relative;
  overflow-y: scroll;
  transform-style: preserve-3d;
  background-attachment: fixed;
}

.header-card::-webkit-scrollbar {
  width: 0;
}

.card-header {
  padding: 10px 45px;
  background: #22845c;
  text-align: justify;
  color: #fff;
}

.card-text {
  padding: 10px 25px;
  text-align: justify;
}

.resumo {
  font-style: italic;
}

#data {
  font-weight: bold;
}

#principalTutorial {
  background-color: #868585;
  height: 400px; 
}

#secundaria {
  background-color: #fff;
  height: 400px;
}

#header {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px 68px;
  color: #fff;
  text-align: left;
  font-weight: bold;
}

#headerNoticias {
  display: flex;
  flex-direction: row;
  position: relative;
  flex-wrap: nowrap;
  justify-content: space-between;
  padding: 5px 68px;
  color: #fff;
  text-align: left;
  font-weight: bold;
  margin-top: -366px;
}

#header h1 {
  font-size: 38px;
  font-weight: bold;
  text-shadow: 0px 2px 14px black;
  position: relative;
  top: -330px
}

#headerTutorial {
  position: relative;
  top: -400px;
}

#headerTutorial h1 {
  font-size: 38px;
  font-weight: bold;
  text-shadow: 0px 2px 14px black;
  position: relative;
  float: left;
  margin-left: 70px;
  top: 75px;
  color: #FFF
}

#headerTutorial img {
  position: relative;
  top: 75px;
  float: right;
  margin-right: 70px;
}

#cardNoticias {
  display: flex;
  position: relative;
  justify-content: center;
  /* top: 1px; */
  top: 37px;
}

#cardTutorial {
  display: flex;
  position: relative;
  justify-content: center;
  top: 140px;
}

.card-title {
  background: #22845c;
}
.card {
  margin-top: -614px;
  box-shadow: inset 0 0 1em rgb(0 0 0 / 20%), 0 0 1em rgb(255 255 255 / 9%);
  margin-bottom: 50px;
}

#logo {
  position: relative;
  top: -330px;
  width: 90px;
  height: 111px;
}

.div-pai {
  height: 100vh;
}
</style>